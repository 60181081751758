import ReactDOM from 'react-dom';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { configureStore } from 'redux/store';

const currentVersion = '1';
function checkVersion() {
    const storedVersion = localStorage.getItem('appVersion');
    if (storedVersion !== currentVersion) {
        localStorage.clear();
        localStorage.setItem('appVersion', currentVersion);
        window.location.href = '/account/login';
    }
}
checkVersion();

ReactDOM.render(
    <Provider store={configureStore({})}>
        <App />
    </Provider>,
    document.getElementById('root')
);

reportWebVitals();
